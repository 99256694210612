





































import { defineComponent } from '@vue/composition-api';
import WizardButton from '@/components/clickables/WizardButton.global.vue';
import { mapActions, mapGetters, mapState } from 'vuex';

export default defineComponent<any, Record<string, unknown>, any, any>({
  components: { WizardButton },
  props: {},
  data() {
    return {
      saving: false,
      openModal: false,
    };
  },
  computed: {
    ...mapGetters(['allBuildingsHaveElevator']),
    ...mapState({
      tab: (state: any) => state.EnterpriseProjectCreateModule.tab,
      projectReference: (state: any) => state.EnterpriseProjectCreateModule?.newProjectData?.projectReference,
    }),
    buttonDisabled(): boolean {
      if (this.tab !== 'elevatorSelection') {
        return false;
      }
      return !this.allBuildingsHaveElevator;
    },
  },
  methods: {
    ...mapActions({
      addToastMessages: 'ADD_TOAST_MESSAGES',
    }),
    async handleConfirmModal() {
      this.saving = true;
      // Save the page content to the DB
      await this.$store.dispatch('saveToDB', { apolloClient: this.$apollo, finalSave: true });
      await this.addToastMessages({
        messages: [{ text: 'Projekt wurde angelegt', class: 'success' }],
      });
      this.openModal = false;
      this.saving = false;
      // Check if we are already on the project details page
      if (this.$route.name !== 'AppEnterpriseProject/ProjectDetails') {
        // Navigate to project details page
        await this.$router.push({
          name: 'AppEnterpriseProject/ProjectDetails',
          params: {
            projectReference: this.projectReference,
          },
        });
      }
      // Hide modal
      this.$emit('hide');
    },
  },
});
