var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-layout',{attrs:{"use":"core-layout-default"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('Flex',[_c('Cell',{attrs:{"basis":0.5}},[_c('Panel',{staticClass:"filter-panel"},[_c('Cell',[_c('div',{staticClass:"filter-holder"},[_c('Flex',{staticClass:"filter-selects",attrs:{"items":"center"}},[_c('EnterpriseIcon',{staticClass:"app-header-icon"}),_c('h2',{staticClass:"page-header-text"},[_vm._v(_vm._s(_vm.$store.getters.app.label))]),_c('Cell',{attrs:{"max":600}},[_c('ui-input-text',{attrs:{"placeholder":"Kunden-ID / Kundenname / Projekt-ID / PDTP # / CCR # / Liegenschafts-ID / Gateway-ID","data-element-id":'app-enterprise-project-project-list-search-input',"clearable":true},on:{"update":_vm.onFilterInput},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('Cell',{attrs:{"max":300}},[_c('ui-input-text',{attrs:{"placeholder":"Nach email admin kunde","data-element-id":'app-enterprise-project-project-list-sales-person-search-input'},on:{"input":_vm.onFilterInput},model:{value:(_vm.salesPersonSearch),callback:function ($$v) {_vm.salesPersonSearch=$$v},expression:"salesPersonSearch"}})],1),_c('Cell',{attrs:{"max":300}},[_c('ui-input-select',{attrs:{"value":_vm.statusFilter,"options":_vm.statusOptions,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"placeholder":"Nach Status filtern","disabled":_vm.loading,"data-element-id":'app-enterprise-project-project-details-status-filter-selector'},on:{"update":function($event){return _vm.onStatusFilterUpdate($event)}}})],1)],1),_c('div',[(_vm.hasAppRole(_vm.appRoles.APP_ADMIN_ENTERPRISE_PROJECT_CREATE))?_c('Cell',[_c('ui-button',{attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.onCreateProject()}}},[_vm._v("Neues Projekt erstellen")]),(_vm.projectEditEnabled)?_c('EnterpriseProjectCreateModal',{ref:"projectCreateModal",attrs:{"project-reference":null},on:{"clear":function($event){return _vm.clearState()},"hide":function($event){return _vm.refetchQueries()}}}):_vm._e()],1):_vm._e(),(!_vm.hasAppRole(_vm.appRoles.APP_ADMIN_ENTERPRISE_PROJECT_CREATE))?_c('Cell',[_c('Form',{attrs:{"initial-data":{},"action":_vm.uploadProjectFile},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var busy = ref.busy;
var formData = ref.formData;
var submit = ref.submit;
return [_c('div',{staticClass:"import-form"},[_c('div',{staticClass:"import-file-input"},[_c('ui-form-field',{attrs:{"input":"ui-input-file","path":"file","accept":"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel","label":"Projekt importieren"}})],1),_c('div',{staticClass:"import-button-holder"},[_c('ui-wizard-button',{attrs:{"disabled":!formData.file,"busy":busy},on:{"click":submit}},[_vm._v(" Hochladen ")])],1)])]}}],null,false,3965843219)})],1):_vm._e()],1)],1)])],1),_c('Panel',{staticClass:"project-list-table"},[(_vm.loading)?_c('TableSkeleton'):_vm._e(),(_vm.projectList && _vm.projectList.items.length === 0 && !_vm.loading && !_vm.$apollo.queries.projectList.loading)?_c('ui-form-message',[_vm._v("Keine Daten gefunden")]):_vm._e(),(_vm.projectList && _vm.projectList.items.length && !_vm.loading)?_c('div',[_c('EnterpriseProjectsTable',{attrs:{"loading":_vm.loading,"project-list":_vm.projectList.items}})],1):_vm._e(),(_vm.totalPages > 1)?_c('Pagination',{attrs:{"total-pages":_vm.totalPages,"value":_vm.currentPage,"data-element-id":'app-enterprise-project-project-list-table-pagination'},on:{"input":function($event){return _vm.onPaginationUpdate($event)}}}):_vm._e()],1)],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }