import {
  GetProjectDraftAuditLogs_projectDraftAuditLogs,
  GetProjectDraftAuditLogs_projectDraftAuditLogs_items,
} from '@/features/app-enterprise-project/views/enterprise-project-audit-logs/graphql/__generated__/GetProjectDraftAuditLogs';
import { GetProjectStatusAuditLogs_projectItemStatusAuditLogs_items } from '@/features/app-enterprise-project/views/enterprise-project-audit-logs/graphql/__generated__/GetProjectStatusAuditLogs';
import { EnterpriseProjectObjectUseCase } from '@/features/app-enterprise-project/enterprise-project-constants';

interface TableRow {
  id: string;
  pdtpNumber: string | null | undefined;
  address: string;
  section: string | null;
  fieldEdited: string | null;
  previousValue: string | number | null;
  updatedValue: string | number | null;
  userName: string | null;
  timestamp: DateTime;
}

interface ItemStatusAuditTableRow {
  id: string;
  pdtpNumber: string | null | undefined;
  address: string;
  previousStatus: string | null;
  newStatus: string | null;
  userName: string | null;
  timestamp: DateTime;
}

const excludeEntities = [
  'buildings',
  'mainBuildings',
  'propertyAssociationCompleted',
  'propertyInformationCompleted',
  'buildingsImportCompleted',
  'generalInformationCompleted',
];

function isExcludedEntity(changedEntity: string | null): boolean {
  if (changedEntity === null) {
    return true;
  }
  if (changedEntity.includes('buildings')) {
    return true;
  }
  if (changedEntity.includes('mainBuildings')) {
    return true;
  }
  // Check if the changedEntity is in the excludeEntities list
  return excludeEntities.includes(changedEntity);
}

// New map for readable properties
const readablePropertiesMap = new Map<string, string>([
  ['firstName', 'Vorname'],
  ['surname', 'Nachname'],
  ['title', 'Titel'],
  ['email', 'E-Mail'],
  ['phoneNumber', 'Telefonnummer'],
  ['monthlyPriceInEuros', 'Monatlicher Preis in Euro'],
  ['oneTimePriceInEuros', 'Einmaliger Preis in Euro'],
  ['useCaseType', 'Anwendungsfalltyp'],
  ['checked', 'Ausgewählt'],
  ['mainService', 'Hauptdienst ausgewählt'],
  ['option', 'Option ausgewählt'],
  ['mainBuildingId', 'Verknüpft mit Liegenschaft'],
  ['hasElevator', 'Aufzug'],
]);

const readableSectionMap = new Map<string, string>([['INFRASTRUCTURE_VARIANT', 'Infrastrukturvariante']]);

const readableChangesMap = new Map<string, string>([
  ['HEATING_MONITORING', 'Heizungsmonitoring (HzM)'],
  ['HEATING_PROFESSIONAL', 'Heizungsprofi (HzP)'],
  ['SMART_ELEVATOR_MAINTENANCE', 'SmarteAufzugswartung (SAM)'],
  ['ELEVATOR_MONITORING', 'Aufzugsmonitoring (AzM)'],
  ['true', 'Checked'],
  ['false', 'Unchecked'],
]);

function assignReadableProperties(currentItem: GetProjectDraftAuditLogs_projectDraftAuditLogs_items): string {
  if (!currentItem.changedEntity) return '';
  return readablePropertiesMap.get(currentItem.changedEntity) || currentItem.changedEntity;
}

function assignReadableSection(currentItem: GetProjectDraftAuditLogs_projectDraftAuditLogs_items): string {
  if (!currentItem.changedOption && currentItem.changedEntity === 'hasElevator') {
    return 'Einstellungen für den Aufzug';
  }
  if (!currentItem.changedOption) return '';
  return readableSectionMap.get(currentItem.changedOption) || currentItem.changedOption;
}

function assignReadableValues(value: string | null): string {
  if (!value) return '';
  return readableChangesMap.get(value) || value;
}

export function transformChangeListToTableData(
  projectAuditLogs: GetProjectDraftAuditLogs_projectDraftAuditLogs_items[],
): TableRow[] {
  const tableData: TableRow[] = [];

  // Iterate through changeList items and group by buildingId and changedEntity
  for (let i = 0; i < projectAuditLogs.length; i++) {
    const currentItem = projectAuditLogs[i];
    // Don't add the change if the changeEntity is in the excludeEntities list
    if (isExcludedEntity(currentItem.changedEntity)) {
      continue;
    }
    // Create a row based on currentItem and nextItem
    tableData.push({
      id: currentItem.objectListItem?.buildingReferenceId || 'No ID',
      pdtpNumber:
        currentItem.objectListItem?.ccrReferenceId || currentItem.objectListItem?.externalId || 'Noch nicht zugewiesen',
      address: currentItem.buildingName,
      section: assignReadableSection(currentItem),
      fieldEdited: assignReadableProperties(currentItem),
      previousValue: assignReadableValues(currentItem.oldValue),
      updatedValue: assignReadableValues(currentItem.newValue),
      userName: currentItem?.user?.name || null,
      timestamp: currentItem.timestamp, // Assuming no timestamp in data
    });
  }

  return tableData;
}

export function transformStatusAuditLogsToTableData(
  projectItemStatusAuditLogs: GetProjectStatusAuditLogs_projectItemStatusAuditLogs_items[],
): ItemStatusAuditTableRow[] {
  const tableData: ItemStatusAuditTableRow[] = [];

  // Iterate through changeList items and group by buildingId and changedEntity
  for (let i = 0; i < projectItemStatusAuditLogs.length; i++) {
    const currentItem = projectItemStatusAuditLogs[i];
    // Create a row based on currentItem and nextItem
    tableData.push({
      id: currentItem.objectListItem?.buildingReferenceId || 'No ID',
      pdtpNumber:
        currentItem.objectListItem?.ccrReferenceId || currentItem.objectListItem?.externalId || 'Noch nicht zugewiesen',
      address: currentItem.buildingName,
      previousStatus: currentItem.oldStatus,
      newStatus: currentItem.newStatus,
      userName: currentItem?.user?.name || null,
      timestamp: currentItem.timestamp,
    });
  }

  return tableData;
}
