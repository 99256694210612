







































import { defineComponent } from '@vue/composition-api';
import InputText from '@/features/ui/inputs/InputText.global.vue';
import InputCheckbox from '@/features/ui/inputs/InputCheckbox.global.vue';
import { mapGetters, mapState } from 'vuex';
import { ProjectBuilding } from '@/features/core/store/page-modules/enterprise-project-create/enterpriseProjectCreateModule';
import WizardButton from '@/components/clickables/WizardButton.global.vue';
import Pagination from '@/features/ui/pagination/Pagination.global.vue';
import FormMessage from '@/features/ui/form/FormMessage.global.vue';

interface PropertyBuildingAssociationData {
  search: string;
  selectedBuildings: boolean[];
  currentPage: number;
  columnsCount: number;
  itemsPerColumn: number;
  lastSelectedBuildingId: number | null;
}

export default defineComponent<any, Record<string, unknown>, PropertyBuildingAssociationData, any, any>({
  components: {
    FormMessage,
    WizardButton,
    InputCheckbox,
    InputText,
    Pagination,
  },
  props: {},
  data() {
    return {
      search: '',
      selectedBuildings: [],
      currentPage: 1,
      columnsCount: 4,
      itemsPerColumn: 20,
      lastSelectedBuildingId: null,
      showImportedBuildings: true,
    };
  },
  computed: {
    ...mapGetters(['buildingsWithAuzug', 'activeLiegenschaftWithAssociatedBuildings']),
    ...mapState({
      buildings: (state: any) => state.EnterpriseProjectCreateModule.newProjectData.buildings,
      activeStep2BuildingId: (state: any) => state.EnterpriseProjectCreateModule.activeStep2BuildingId,
    }),
    filteredBuildings(): ProjectBuilding[] {
      const searchLower = this.search.toLowerCase();
      return this.activeLiegenschaftWithAssociatedBuildings.filter(
        (building: any) => !this.search || building.name.toLowerCase().includes(searchLower),
      );
    },
    columns(): number[] {
      return Array.from({ length: this.columnsCount }, (_, i) => i + 1);
    },
    itemsPerPage(): number {
      return this.columnsCount * this.itemsPerColumn;
    },
    totalPages(): number {
      return Math.ceil(this.filteredBuildings.length / this.itemsPerPage);
    },
  },
  watch: {
    buildings: {
      handler() {
        this.updateSelectedBuildings();
      },
      deep: true,
    },
    selectedBuildings: {
      handler() {
        this.updateBuildingsWithElevators();
      },
      deep: true,
    },
    filteredBuildings: {
      handler() {
        this.updateSelectedBuildings();
      },
      deep: true,
    },
    activeStep2BuildingId: {
      handler() {
        this.currentPage = 1;
      },
      deep: true,
    },
  },
  async created() {
    this.updateSelectedBuildings();
  },
  methods: {
    updateSelectedBuildings() {
      this.selectedBuildings = [];
      const tempSelectedBuildings = [];
      for (let i = 0; i < this.filteredBuildings.length; i++) {
        tempSelectedBuildings[this.filteredBuildings[i].buildingId] = this.filteredBuildings[i].hasElevator;
      }
      this.selectedBuildings = tempSelectedBuildings;
    },
    updateBuildingsWithElevators() {
      for (let i = 0; i < this.filteredBuildings.length; i++) {
        this.filteredBuildings[i].hasElevator = !!this.selectedBuildings[this.filteredBuildings[i].buildingId];
        this.$store.commit('setBuildingHasElevator', {
          buildingId: this.filteredBuildings[i].buildingId,
          hasElevator: this.filteredBuildings[i].hasElevator,
        });
      }
    },
    getCurrentPageBuildingsForColumn(column: number): ProjectBuilding[] {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage + (column - 1) * this.itemsPerColumn;
      const endIndex = startIndex + this.itemsPerColumn;
      return this.filteredBuildings.slice(startIndex, endIndex);
    },
    selectAllFilteredBuildings() {
      this.showImportedBuildings = false;
      const localSelectedBuildings = this.selectedBuildings;
      for (let i = 0; i < this.filteredBuildings.length; i++) {
        localSelectedBuildings[this.filteredBuildings[i].buildingId] = true;
      }
      this.selectedBuildings = localSelectedBuildings;
      this.updateBuildingsWithElevators();
      this.showImportedBuildings = true;
    },
    deselectAllFilteredBuildings() {
      this.showImportedBuildings = false;
      const localSelectedBuildings = this.selectedBuildings;
      for (let i = 0; i < this.filteredBuildings.length; i++) {
        localSelectedBuildings[this.filteredBuildings[i].buildingId] = false;
      }
      this.selectedBuildings = localSelectedBuildings;
      this.updateBuildingsWithElevators();
      this.showImportedBuildings = true;
    },
    onBuildingClick(event: MouseEvent, buildingId: number) {
      this.showImportedBuildings = false;
      if (event.shiftKey && this.lastSelectedBuildingId !== null) {
        this.selectRange(buildingId);
        this.lastSelectedBuildingId = null;
      } else {
        this.lastSelectedBuildingId = buildingId;
      }
      this.showImportedBuildings = true;
    },
    selectRange(buildingId: number) {
      const start = this.filteredBuildings.findIndex(
        (building: ProjectBuilding) => building.buildingId === this.lastSelectedBuildingId,
      );
      const end = this.filteredBuildings.findIndex((building: ProjectBuilding) => building.buildingId === buildingId);
      const [startIndex, endIndex] = start <= end ? [start, end] : [end, start];
      const localSelectedBuildings = this.selectedBuildings;
      for (let i = startIndex; i <= endIndex; i++) {
        localSelectedBuildings[this.filteredBuildings[i].buildingId] = true;
      }
      this.selectedBuildings = localSelectedBuildings;
      this.updateBuildingsWithElevators();
    },
  },
});
