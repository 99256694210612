import { Component, Vue } from 'vue-property-decorator';

@Component
export class InputValidationMixin extends Vue {
  protected validateEmail(email: string): boolean {
    // Regular expression pattern for email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the pattern
    return emailPattern.test(email);
  }
}
