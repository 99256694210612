var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-header-test"},[_c('h3',{staticClass:"column-header"},[_vm._v("Technischer AP Kunde")]),_c('p',[_vm._v("Bitte hinterlegen Sie eine Kontaktperson je Liegenschaft.")]),(_vm.activeBuilding)?_c('p',[_vm._v(" Liegenschaft:"),_c('strong',{staticClass:"margin-left"},[_vm._v(_vm._s(_vm.activeBuilding.name))])]):_vm._e(),_c('Form',{ref:"buildingInformationForm",attrs:{"initial-data":{
      firstName: _vm.firstName,
      surname: _vm.surname,
      title: _vm.title,
      phoneNumber: _vm.phoneNumber,
      email: _vm.email,
    },"action":function (data) { return _vm.updateBuildingInformation(data); }},on:{"submitted":function($event){return _vm.$emit('project-data-updated')},"error":function($event){return _vm.$emit('error', $event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var dirty = ref.dirty;
    var formData = ref.formData;
return [_c('FormField',{attrs:{"input":"ui-input-text","data-element-id":'app-enterprise-project-create-first-name',"path":"title","label":"Anrede","disabled":!_vm.activeBuilding,"error":dirty && !formData.title,"description":dirty && !formData.title ? 'Bitte geben Sie einen Anrede ein.' : ''},on:{"input":function($event){return _vm.updateStore(formData)}}}),_c('div',{staticClass:"input-horizontal"},[_c('FormField',{staticClass:"input-flex",attrs:{"input":"ui-input-text","data-element-id":'app-enterprise-project-create-title',"path":"firstName","label":"Vorname","disabled":!_vm.activeBuilding,"error":dirty && !formData.firstName,"description":dirty && !formData.firstName ? 'Bitte geben Sie einen Vorname ein.' : ''},on:{"input":function($event){return _vm.updateStore(formData)}}}),_c('FormField',{staticClass:"input-flex",attrs:{"input":"ui-input-text","data-element-id":'app-enterprise-project-create-surname',"path":"surname","label":"Nachname","disabled":!_vm.activeBuilding,"error":dirty && !formData.surname,"description":dirty && !formData.surname ? 'Bitte geben Sie einen Name ein.' : ''},on:{"input":function($event){return _vm.updateStore(formData)}}})],1),_c('FormField',{attrs:{"input":"ui-input-text","data-element-id":'app-enterprise-project-create-phone-number',"path":"phoneNumber","label":"Telefon","disabled":!_vm.activeBuilding,"error":dirty && !formData.phoneNumber,"description":dirty && !formData.phoneNumber ? 'Bitte geben Sie eine gültige Telefonnummer ein.' : ''},on:{"input":function($event){return _vm.updateStore(formData)}}}),_c('FormField',{attrs:{"input":"ui-input-text","data-element-id":'app-enterprise-project-create-email',"path":"email","label":"e-Mail","disabled":!_vm.activeBuilding,"error":dirty && !_vm.validateEmail(formData.email),"description":dirty && !_vm.validateEmail(formData.email) ? 'Bitte geben Sie eine gültige E-Mail Adresse ein.' : '',"type":'email'},on:{"input":function($event){return _vm.updateStore(formData)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }