




































import { defineComponent } from '@vue/composition-api';
import Table from '@/features/ui/table/Table.global.vue';
import TableSkeleton from '@/features/ui/table/TableSkeleton.global.vue';
import { LiegenshaftStatusMappings } from '@/features/app-enterprise-project/views/enterprise-project-details/project-details-constants';
import { EnterpriseProjectBuildingStatus } from '@/types/iot-portal';

export default defineComponent<any, Record<string, unknown>, any, any>({
  components: {
    TableSkeleton,
    Table,
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    auditLogs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      columns: [
        { name: 'id', label: 'Liegenschafts ID' }, // Liegenschafts ID
        { name: 'pdtpNumber', label: 'PDTP/CCR-Nummer' }, // PDTP/CCR-Nummer
        { name: 'address', label: 'Anschrift' }, // Object name (Address), including post code and city
        { name: 'previousStatus', label: 'Vorheriger Wert' }, // Value in the field before the edit was made.
        { name: 'newStatus', label: 'Aktualisierter Wert' }, // Value in the field after the edit was made.
        { name: 'userName', label: 'Benutzer' }, // Username of the GK4.0 user who made the edit.
        { name: 'timestamp', label: 'Zeitstempel' }, // Timestamp when the edit was saved.
      ],
    };
  },
  computed: {},
  methods: {
    // Function to get german names for statuses from LiegenshaftStatusMappings map
    getStatusName(status: EnterpriseProjectBuildingStatus) {
      const statusItem = LiegenshaftStatusMappings.get(status);
      // Get label
      return statusItem?.label;
    },
  },
});
