
















































































import { defineComponent } from '@vue/composition-api';
import FormField from '@/features/ui/form/FormField.global.vue';
import Form from '@/features/ui/form/Form.global.vue';
import { mapState } from 'vuex';

interface ProjectBuildingInformationMethods {
  [key: string]: (...args: any[]) => void;
  updateBuildingInformation(formData: any): void;
}
export default defineComponent<any, Record<string, unknown>, any, any, ProjectBuildingInformationMethods>({
  components: { Form, FormField },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapState({
      activeStep2BuildingId: (state: any) => {
        return state.EnterpriseProjectCreateModule.activeStep2BuildingId;
      },
      activeBuilding: (state: any) => {
        if (
          state.EnterpriseProjectCreateModule.newProjectData.buildings &&
          state.EnterpriseProjectCreateModule.newProjectData.buildings?.length > 0
        ) {
          // Find active building by buildingId
          return state.EnterpriseProjectCreateModule.newProjectData.buildings.find(
            (building: any) => building.buildingId === state.EnterpriseProjectCreateModule.activeStep2BuildingId,
          );
        }
      },
      firstName: (state: any) =>
        state.EnterpriseProjectCreateModule.newProjectData.buildings.find(
          (building: any) => building.buildingId === state.EnterpriseProjectCreateModule.activeStep2BuildingId,
        )?.liegenshaftInformation.firstName,
      surname: (state: any) =>
        state.EnterpriseProjectCreateModule.newProjectData.buildings.find(
          (building: any) => building.buildingId === state.EnterpriseProjectCreateModule.activeStep2BuildingId,
        )?.liegenshaftInformation.surname,
      title: (state: any) =>
        state.EnterpriseProjectCreateModule.newProjectData.buildings.find(
          (building: any) => building.buildingId === state.EnterpriseProjectCreateModule.activeStep2BuildingId,
        )?.liegenshaftInformation.title,
      phoneNumber: (state: any) =>
        state.EnterpriseProjectCreateModule.newProjectData.buildings.find(
          (building: any) => building.buildingId === state.EnterpriseProjectCreateModule.activeStep2BuildingId,
        )?.liegenshaftInformation.phoneNumber,
      email: (state: any) =>
        state.EnterpriseProjectCreateModule.newProjectData.buildings.find(
          (building: any) => building.buildingId === state.EnterpriseProjectCreateModule.activeStep2BuildingId,
        )?.liegenshaftInformation.email,
    }),
  },
  mounted() {
    if (this.activeBuilding) {
      this.updateStore({
        firstName: this.activeBuilding.liegenshaftInformation.firstName,
        surname: this.activeBuilding.liegenshaftInformation.surname,
        title: this.activeBuilding.liegenshaftInformation.title,
        phoneNumber: this.activeBuilding.liegenshaftInformation.phoneNumber,
        email: this.activeBuilding.liegenshaftInformation.email,
      });
    }
  },
  methods: {
    async updateBuildingInformation(formData: any) {
      this.$store.commit('setShowPropertyInformationCopySelection', false);
      await this.$store.dispatch('saveBuildingInformation', {
        buildingInformation: formData,
        apolloClient: this.$apollo,
      });
      return;
    },
    // Form
    validateEmail(email: string) {
      // Regular expression pattern for email validation
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      // Test the email against the pattern
      return emailPattern.test(email);
    },
    validateForm(formData) {
      return (
        this.validateEmail(formData.email) &&
        formData.phoneNumber &&
        formData.firstName &&
        formData.surname &&
        formData.title
      );
    },
    updateStore(formData: any) {
      this.$store.commit('setBuildingInformationFormValid', this.validateForm(formData));
    },
    // Method to expose the submit function to the parent
    triggerSubmit() {
      // Access the submit method using the ref
      const formRef = this.$refs.buildingInformationForm;
      if (formRef && formRef.submit) {
        formRef.submit();
      }
    },
  },
});
