

































import { defineComponent } from '@vue/composition-api';
import Panel from '@/features/ui/panel/Panel.global.vue';
import PropertyList from '@/features/app-enterprise-project/views/enterprise-project-create/components/step2/PropertyList.vue';
import ProjectBuildingInformation from '@/features/app-enterprise-project/views/enterprise-project-create/components/step1/ProjectBuildingInformation.vue';
import ProjectBuildingInformationSelect from '@/features/app-enterprise-project/views/enterprise-project-create/components/step1/ProjectBuildingInformationSelect.vue';
import { mapGetters, mapState } from 'vuex';
import { ProjectBuilding } from '@/features/core/store/page-modules/enterprise-project-create/enterpriseProjectCreateModule';
import WizardButton from '@/components/clickables/WizardButton.global.vue';

export default defineComponent<any, Record<string, unknown>, any, any>({
  components: {
    WizardButton,
    ProjectBuildingInformationSelect,
    ProjectBuildingInformation,
    PropertyList,
    Panel,
  },
  props: {},
  data() {
    return {
      buildingFormData: null,
    };
  },
  computed: {
    ...mapGetters(['buildingInformationFormValid', 'showPropertyInformationCopySelection']),
    ...mapState({
      newProjectData: (state: any) => state.EnterpriseProjectCreateModule.newProjectData,
      buildings: (state: any) => state.EnterpriseProjectCreateModule.newProjectData.buildings,
      activeStep1BuildingId: (state: any) => state.EnterpriseProjectCreateModule.activeStep1BuildingId,
      activeStep2BuildingId: (state: any) => state.EnterpriseProjectCreateModule.activeStep2BuildingId,
      saving: (state: any) => state.EnterpriseProjectCreateModule.saving,
      activeBuilding: (state: any) => {
        if (
          state.EnterpriseProjectCreateModule.newProjectData.buildings &&
          state.EnterpriseProjectCreateModule.newProjectData.buildings?.length > 0
        ) {
          return state.EnterpriseProjectCreateModule.newProjectData.buildings.find(
            (building: ProjectBuilding) =>
              building.buildingId === state.EnterpriseProjectCreateModule.activeStep1BuildingId,
          );
        }
      },
      // Boolean for if all L buildings has information
      allLiegenshaftInformationCompleted(state: any) {
        return state.EnterpriseProjectCreateModule.allLiegenshaftInformationCompleted;
      },
    }),
  },
  methods: {
    goToStep(step: string) {
      this.$store.dispatch('setUpdateTab', step);
      if (step !== 'step1') {
        this.$store.dispatch('saveToDB', { apolloClient: this.$apollo, finalSave: false });
      }
    },
    submitBuildingForm() {
      // Access the form's method triggerSubmit via ref
      if (this.$refs.projectBuildingForm) {
        this.$refs.projectBuildingForm.triggerSubmit();
      }
    },
    setShowPropertyInformationCopySelection() {
      this.$store.commit('setShowPropertyInformationCopySelection', !this.showPropertyInformationCopySelection);
    },
  },
});
