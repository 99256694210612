















































































































































import { APOLLO_CLIENT } from '@/features/core/container/model';
import {
  UpdateAlertHeatingSystemRuleMutation,
  UpdateAlertHeatingSystemRuleMutationVariables,
} from '@/hsc-api/mutations/__generated__/UpdateAlertHeatingSystemRuleMutation';
import {
  AlertHeatingSystemByCustomerIdQuery,
  AlertHeatingSystemByCustomerIdQueryVariables,
} from '@/hsc-api/queries/__generated__/AlertHeatingSystemByCustomerIdQuery';
import {
  AlertHeatingSystemRulesInput,
  CalloutTimeDay,
  CalloutTimeInput,
  HeatingPeriodInput,
  OutsideTemperatureInput,
  ReleaseDurationInput,
} from '@/types/hsc-types';
import { StringProp } from '@/util/prop-decorators';
import { isNonEmptyString } from '@/util/string';
import { isNull, isUndefined } from 'lodash';
import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import {
  CalloutTimeMeta,
  CalloutTimeOutput,
  defaultCalloutTimeConfigurations,
  defaultCalloutTimeCOnfigurationsMeta,
} from './model';

import updateAlertRuleMutation from '@/hsc-api/mutations/UpdateAlertHeatingSystemRuleMutation.gql';
import { ApolloQueryResult } from 'apollo-client';
import {
  CreateAlertHeatingSystemRuleMutation,
  CreateAlertHeatingSystemRuleMutationVariables,
} from '@/hsc-api/mutations/__generated__/CreateAlertHeatingSystemRuleMutation';
import query from '@/hsc-api/queries/AlertHeatingSystemByCustomerIdQuery.gql';
import createAlertRuleMutation from '@/hsc-api/mutations/CreateAlertHeatingSystemRuleMutation.gql';

@Component({
  apollo: {
    alertHeatingSystemRuleByIdOrCustomerId: {
      query,
      client: APOLLO_CLIENT.HEATING_SYSTEM_COLLECTOR_CLIENT,
      variables(this: AlertGeneralSettings): AlertHeatingSystemByCustomerIdQueryVariables {
        return {
          customerId: this.customerId,
        };
      },
      async result(
        this: AlertGeneralSettings,
        { data }: ApolloQueryResult<AlertHeatingSystemByCustomerIdQuery>,
      ): Promise<void> {
        if (data.alertHeatingSystemRuleByIdOrCustomerId) {
          this.alertHeatingSystemRule = data.alertHeatingSystemRuleByIdOrCustomerId;
          if (this.alertHeatingSystemRule?.calloutTimeConfiguration?.length === 0) {
            this.alertHeatingSystemRule.calloutTimeConfiguration = this.calloutTimeConfigurations;
          }
        } else {
          const { data: mutationData } = await this.$apollo.mutate<
            CreateAlertHeatingSystemRuleMutation,
            CreateAlertHeatingSystemRuleMutationVariables
          >({
            mutation: createAlertRuleMutation,
            client: APOLLO_CLIENT.HEATING_SYSTEM_COLLECTOR_CLIENT,
            variables: {
              input: {
                customerId: this.customerId,
              },
            },
          });

          this.alertHeatingSystemRule = mutationData?.createAlertHeatingSystemRule;
          if (this.alertHeatingSystemRule?.calloutTimeConfiguration?.length === 0) {
            this.alertHeatingSystemRule.calloutTimeConfiguration = this.calloutTimeConfigurations;
          }
        }

        this.checkHeatingPeriodDates();
      },
    },
  },
  data() {
    return {
      alertHeatingSystemRule: {
        releaseDurationConfiguration: { isActive: false },
        heatingPeriodConfiguration: { isActive: false },
        outsideTemperatureConfiguration: { isActive: false },
        calloutTimeConfiguration: [],
      } as unknown as AlertHeatingSystemRulesInput,
    };
  },
})
export default class AlertGeneralSettings extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  private alertHeatingSystemRule?: AlertHeatingSystemByCustomerIdQuery['alertHeatingSystemRuleByIdOrCustomerId'];

  private get loading(): boolean {
    return this.$apollo.queries.alertHeatingSystemRuleByIdOrCustomerId.loading;
  }

  private getCalloutTimeMetaByDay(calloutDay: CalloutTimeDay): CalloutTimeMeta | undefined {
    return this.calloutTimeConfigurationsMeta.find(({ day }) => day === calloutDay);
  }

  private getCalloutTimeConfigurationByDay(calloutTimeDay: CalloutTimeDay): CalloutTimeOutput | unknown {
    return this.alertHeatingSystemRule?.calloutTimeConfiguration?.find(({ day }) => day === calloutTimeDay) || {};
  }

  private onCloseCalloutTime(day: CalloutTimeDay, field: 'from' | 'to', HH: string, mm: string): void {
    const value = isNonEmptyString(HH) ? `${HH}:${mm !== '' ? mm : '00'}` : null;
    this.onUpdateCalloutTime(day, field, value);
  }

  private calloutTimeoutEnabledHours(
    selectedHour: CalloutTimeOutput['from'] | CalloutTimeOutput['to'] | undefined,
  ): number[][] {
    if (isNull(selectedHour) || isUndefined(selectedHour)) return [[0, 23]];
    //selectedHour will look like HH:mm so we have to split the string and get the hour
    const hour = selectedHour.split(':')[0];
    const from = parseInt(hour);
    return [[from + 1 < 23 ? from + 1 : from, 23]];
  }

  private async onUpdate(payload: Partial<AlertHeatingSystemRulesInput>): Promise<void> {
    if (!this.alertHeatingSystemRule) return;

    const { data } = await this.$apollo.mutate<
      UpdateAlertHeatingSystemRuleMutation,
      UpdateAlertHeatingSystemRuleMutationVariables
    >({
      mutation: updateAlertRuleMutation,
      client: APOLLO_CLIENT.HEATING_SYSTEM_COLLECTOR_CLIENT,
      variables: {
        id: this.alertHeatingSystemRule?.id,
        input: {
          customerId: this.customerId,
          ...payload,
        },
      },
    });
    if (data) {
      this.alertHeatingSystemRule = data.updateAlertHeatingSystemRule;
      if (this.alertHeatingSystemRule?.calloutTimeConfiguration?.length === 0) {
        this.alertHeatingSystemRule.calloutTimeConfiguration = this.calloutTimeConfigurations;
      }
    }
  }
  private onUpdateReleaseDuration(
    field: keyof ReleaseDurationInput,
    value: ReleaseDurationInput['isActive'] | ReleaseDurationInput['value'],
  ): void {
    if (!this.alertHeatingSystemRule) return;
    if (!this.alertHeatingSystemRule.releaseDurationConfiguration) return;
    if (!value && typeof value === 'number') return;

    const releaseDurationConfiguration: ReleaseDurationInput = {
      isActive: this.alertHeatingSystemRule.releaseDurationConfiguration.isActive,
      value: this.alertHeatingSystemRule.releaseDurationConfiguration.value,
      [field]: value,
    };

    this.onUpdate({ releaseDurationConfiguration });
  }

  private onUpdateOutsideTemperature(
    field: keyof OutsideTemperatureInput,
    value: OutsideTemperatureInput['isActive'] | OutsideTemperatureInput['threshold'],
  ): void {
    if (!this.alertHeatingSystemRule) return;
    if (!this.alertHeatingSystemRule.outsideTemperatureConfiguration) return;
    if (!value && typeof value === 'number') return;

    const outsideTemperatureConfiguration: OutsideTemperatureInput = {
      isActive: this.alertHeatingSystemRule.outsideTemperatureConfiguration.isActive,
      threshold: this.alertHeatingSystemRule.outsideTemperatureConfiguration.threshold,
      [field]: value,
    };

    this.onUpdate({ outsideTemperatureConfiguration });
  }

  private onUpdateHeatingPeriod(
    field: keyof HeatingPeriodInput,
    value: HeatingPeriodInput['isActive'] | HeatingPeriodInput['from'] | HeatingPeriodInput['to'],
  ): void {
    if (!this.alertHeatingSystemRule) return;
    if (!this.alertHeatingSystemRule.heatingPeriodConfiguration) return;
    if (!value && typeof value === 'number') return;

    const heatingPeriodConfiguration: HeatingPeriodInput = {
      isActive: this.alertHeatingSystemRule.heatingPeriodConfiguration.isActive,
      from: this.alertHeatingSystemRule.heatingPeriodConfiguration.from,
      to: this.alertHeatingSystemRule.heatingPeriodConfiguration.to,
      [field]: value,
    };

    this.onUpdate({ heatingPeriodConfiguration });
  }

  private onUpdateCalloutTime(
    calloutDay: CalloutTimeDay,
    field: keyof CalloutTimeInput,
    value: CalloutTimeInput['isActive'] | CalloutTimeInput['to'] | CalloutTimeInput['from'],
  ): void {
    if (!this.alertHeatingSystemRule) return;
    if (!this.alertHeatingSystemRule.calloutTimeConfiguration) return;
    if (!value && typeof value === 'number') return;

    const calloutTimeInputs = this.alertHeatingSystemRule.calloutTimeConfiguration.map<CalloutTimeInput>(
      ({ day, from, to, isActive }) => ({
        day,
        from,
        to,
        isActive,
      }),
    );

    let modifiedCalloutTimeConfiguration = calloutTimeInputs.find(({ day }) => day === calloutDay);
    if (modifiedCalloutTimeConfiguration) {
      modifiedCalloutTimeConfiguration = {
        ...modifiedCalloutTimeConfiguration,
        [field]: value,
      };

      const calloutTimeConfiguration = calloutTimeInputs.map((value) =>
        modifiedCalloutTimeConfiguration !== undefined && value.day === modifiedCalloutTimeConfiguration.day
          ? modifiedCalloutTimeConfiguration
          : value,
      );

      this.onUpdate({ calloutTimeConfiguration });
    }
  }

  private get since(): Date | null {
    return this.alertHeatingSystemRule &&
      this.alertHeatingSystemRule.heatingPeriodConfiguration &&
      this.alertHeatingSystemRule.heatingPeriodConfiguration.from
      ? new Date(this.alertHeatingSystemRule?.heatingPeriodConfiguration?.from)
      : null;
  }

  private set since(value: Date | null) {
    this.onUpdateHeatingPeriod('from', value?.toISOString());
  }

  private get before(): Date | null {
    return this.alertHeatingSystemRule &&
      this.alertHeatingSystemRule.heatingPeriodConfiguration &&
      this.alertHeatingSystemRule.heatingPeriodConfiguration.to
      ? new Date(this.alertHeatingSystemRule?.heatingPeriodConfiguration?.to)
      : null;
  }

  private set before(value: Date | null) {
    this.onUpdateHeatingPeriod('to', value?.toISOString());
  }

  private get disabledDates(): { from: Date | undefined; to: Date | undefined } {
    return {
      to: this.since || moment().toDate(),
      from: this.before || undefined,
    };
  }

  private get calloutTimeConfigurations(): CalloutTimeOutput[] {
    return defaultCalloutTimeConfigurations || [];
  }

  private get calloutTimeConfigurationsMeta(): CalloutTimeMeta[] {
    return defaultCalloutTimeCOnfigurationsMeta || [];
  }

  private checkHeatingPeriodDates(): void {
    // if end-date month is less than start-date month, increase end-date year.
    // else end-date year should be equal to current.
    if (this.since && this.before) {
      let year = new Date().getFullYear();
      if (this.before.getMonth() < this.since.getMonth()) {
        year++;
        this.before.setFullYear(year);
        this.onUpdateHeatingPeriod('to', this.before?.toISOString());
      } else if (this.since.getFullYear() !== this.before.getFullYear()) {
        this.before.setFullYear(year);
        this.onUpdateHeatingPeriod('to', this.before?.toISOString());
      }
    }
  }
}
