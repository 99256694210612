



















import { defineComponent } from '@vue/composition-api';
import { mapGetters } from 'vuex';
import { ORIGIN_ENDPOINT } from '@/env';

interface AuditLog {
  ID: string;
  userId: string;
  customerId: string;
  auditLogType: string;
  timestamp: string;
  thirdPartyName: string;
  changedValueName: string;
  oldValue: any;
  newValue: any;
  metadata: any;
}

export default defineComponent({
  data() {
    return {
      auditLogs: [] as AuditLog[],
      columns: [
        { label: 'Datum / Uhrzeit', field: 'timestamp' },
        { label: 'Feld', field: 'changedValueName' },
        { label: 'Vorheriger Eintrag', field: 'oldValue' },
        { label: 'Neuer Eintrag', field: 'newValue' },
        { label: 'User', field: 'userId' },
      ],
      pageSize: 10,
      currentPage: 1,
      totalPages: 1,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['openModal']),
  },
  watch: {},
  mounted() {
    this.getAuditLogs();
  },
  methods: {
    async getAuditLogs(): Promise<void> {
      try {
        const res = await fetch(`${ORIGIN_ENDPOINT}/api/v1/audit-log/audit-logs`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = res.ok ? await res.json() : null;
        this.auditLogs = data.auditLogs;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
  },
});
