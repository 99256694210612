


















































import AppRedirectionMixin from '@/features/core/components/mixins/app-redirection';
import GroupsMixin from '@/features/core/components/mixins/groups';
import { StringProp } from '@/util/prop-decorators';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import query from './view.gql';
import {
  AppHeatingSystemTreeNodeViewQuery,
  AppHeatingSystemTreeNodeViewQueryVariables,
} from './__generated__/AppHeatingSystemTreeNodeViewQuery';

@Component({
  apollo: {
    treeNodes: {
      query,
      variables(this: TreeNodeView): AppHeatingSystemTreeNodeViewQueryVariables {
        return {
          treeNodeId: this.treeNodeId, // Customer ID
        };
      },
    },
  },
  data() {
    return {
      treeNodes: undefined,
    };
  },
})
export default class TreeNodeView extends Mixins(AppRedirectionMixin, GroupsMixin) {
  @StringProp(true)
  private readonly treeNodeId!: string;

  private treeNodes?: AppHeatingSystemTreeNodeViewQuery['treeNodes'];

  @Watch('groups')
  private checkContentAvailable(): void {
    // don't redirect to old heating system tab if "open-metadata-modal" is set.
    if (this.hasAppRole(this.appRoles.APP_HEATING_SYSTEM_LIST_V1) && !this.$route.query['open-metadata-modal']) {
      if (this.$route.name !== 'AppHeatingSystem/TreeNode') {
        this.$router.push({ name: 'AppHeatingSystem/TreeNode' });
      }
    } else {
      if (this.$route.name !== 'AppHeatingSystem/HeatingSystemList') {
        this.$router.push({ name: 'AppHeatingSystem/HeatingSystemList' });
      }
    }
  }
}
