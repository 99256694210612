




































import { defineComponent } from '@vue/composition-api';

interface AuditLog {
  date: Date | string;
  section: string;
  field: string;
  previousEntry: string | number;
  newEntry: string | number;
  user: string;
}

export default defineComponent({
  props: {
    openModal: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      visible: false,
      sectionOptions: [
        { value: 'Allgemeine Einstellungen', label: 'Allgemeine Einstellungen' },
        { value: 'Einstellungen für Alarme nach Komponenten', label: 'Einstellungen für Alarme nach Komponenten' },
        { value: 'Empfänger für Alarme auswählen', label: 'Empfänger für Alarme auswählen' },
        { value: 'Indviduelle Konfiguration von Komponenten', label: 'Indviduelle Konfiguration von Komponenten' },
      ],
      auditLogs: [] as AuditLog[],
      columns: [
        { label: 'Datum / Uhrzeit', field: 'date' },
        { label: 'Abschnitt', field: 'section' },
        { label: 'Feld', field: 'field' },
        { label: 'Vorheriger Eintrag', field: 'previousEntry' },
        { label: 'Neuer Eintrag', field: 'newEntry' },
        { label: 'User', field: 'user' },
      ],
      pageSize: 10,
      currentPage: 1,
      totalPages: 1,
      loading: false,
    };
  },
  watch: {
    openModal: function () {
      this.visible = true;
    },
  },
  methods: {
    handleClose() {
      this.visible = false;
    },
  },
});
