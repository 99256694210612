




































































import { defineComponent } from '@vue/composition-api';
import InputText from '@/features/ui/inputs/InputText.global.vue';
import InputCheckbox from '@/features/ui/inputs/InputCheckbox.global.vue';
import { mapActions, mapState } from 'vuex';
import { ProjectBuilding } from '@/features/core/store/page-modules/enterprise-project-create/enterpriseProjectCreateModule';
import WizardButton from '@/components/clickables/WizardButton.global.vue';
import Pagination from '@/features/ui/pagination/Pagination.global.vue';
import FormMessage from '@/features/ui/form/FormMessage.global.vue';
import InputDate from '@/features/ui/inputs/InputDate.global.vue';

interface PropertyBuildingAssociationData {
  search: string;
  selectedBuildings: boolean[];
  currentPage: number;
  columnsCount: number;
  itemsPerColumn: number;
  lastSelectedBuildingId: number | null;
}

export default defineComponent<any, Record<string, unknown>, PropertyBuildingAssociationData, any, any>({
  components: {
    FormMessage,
    WizardButton,
    InputCheckbox,
    InputText,
    Pagination,
    InputDate,
  },
  props: {},
  data() {
    return {
      search: '',
      liegenshaftWIENumber: '',
      installationStartDate: '',
      selectedBuildings: [],
      currentPage: 1,
      columnsCount: 4,
      itemsPerColumn: 20,
      lastSelectedBuildingId: null,
      showImportedBuildings: true,
    };
  },
  computed: {
    ...mapState({
      associatedBuildings: (state: any) => state.EnterpriseProjectCreateModule.associatedBuildings,
      buildings: (state: any) => state.EnterpriseProjectCreateModule.newProjectData.buildings,
      activeStep2BuildingId: (state: any) => state.EnterpriseProjectCreateModule.activeStep2BuildingId,
    }),
    filteredBuildings(): ProjectBuilding[] {
      const activeId = this.activeStep2BuildingId;
      const searchLower = this.search.toLowerCase();
      return this.buildings.filter(
        (building: any) =>
          !building.liegenshaft &&
          (!building.parentProperty || building.parentProperty === activeId) &&
          (!this.search || building.name.toLowerCase().includes(searchLower)),
      );
    },
    totalBuildings(): ProjectBuilding[] {
      return this.buildings.filter((building: any) => !building.liegenshaft);
    },
    columns(): number[] {
      return Array.from({ length: this.columnsCount }, (_, i) => i + 1);
    },
    itemsPerPage(): number {
      return this.columnsCount * this.itemsPerColumn;
    },
    totalPages(): number {
      return Math.ceil(this.filteredBuildings.length / this.itemsPerPage);
    },
  },
  watch: {
    buildings: {
      handler() {
        this.updateSelectedBuildings();
      },
      deep: true,
    },
    selectedBuildings: {
      handler() {
        this.updateAssociations();
      },
      deep: true,
    },
    filteredBuildings: {
      handler() {
        this.updateSelectedBuildings();
      },
      deep: true,
    },
    activeStep2BuildingId: {
      handler() {
        this.currentPage = 1;
        this.updateLiegenshaftWIEField();
        this.updateInstallationStartDate();
      },
      deep: true,
    },
    // Watch the wie number and update the building via the store action
    liegenshaftWIENumber: {
      handler() {
        if (this.liegenshaftWIENumber) {
          this.$store.dispatch('assignActiveBuildingWIENumber', this.liegenshaftWIENumber);
        }
      },
      deep: true,
    },
    installationStartDate: {
      handler() {
        if (this.installationStartDate) {
          this.$store.dispatch('assignActiveBuildingInstallationStartDate', this.installationStartDate);
        }
      },
      deep: true,
    },
  },
  async created() {
    this.updateSelectedBuildings();
    this.updateLiegenshaftWIEField();
    this.updateInstallationStartDate();
  },
  methods: {
    ...mapActions({
      addToastMessages: 'ADD_TOAST_MESSAGES',
    }),
    updateSelectedBuildings() {
      const activeId = this.activeStep2BuildingId;
      this.selectedBuildings = [];
      const tempSelectedBuildings = [];
      for (let i = 0; i < this.filteredBuildings.length; i++) {
        tempSelectedBuildings[this.filteredBuildings[i].buildingId] =
          this.filteredBuildings[i].parentProperty === activeId;
      }
      this.selectedBuildings = tempSelectedBuildings;
    },
    updateAssociations() {
      for (let i = 0; i < this.filteredBuildings.length; i++) {
        if (this.selectedBuildings[this.filteredBuildings[i].buildingId]) {
          this.filteredBuildings[i].parentProperty = this.activeStep2BuildingId;
        } else {
          this.filteredBuildings[i].parentProperty = null;
        }
        this.$store.dispatch('saveBuildingAssociations', this.filteredBuildings[i]);
      }
    },
    getCurrentPageBuildingsForColumn(column: number): ProjectBuilding[] {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage + (column - 1) * this.itemsPerColumn;
      const endIndex = startIndex + this.itemsPerColumn;
      return this.filteredBuildings.slice(startIndex, endIndex);
    },
    selectAllFilteredBuildings() {
      this.showImportedBuildings = false;
      const localSelectedBuildings = this.selectedBuildings;
      for (let i = 0; i < this.filteredBuildings.length; i++) {
        localSelectedBuildings[this.filteredBuildings[i].buildingId] = true;
      }
      this.selectedBuildings = localSelectedBuildings;
      this.updateAssociations();
      this.showImportedBuildings = true;
    },
    deselectAllFilteredBuildings() {
      this.showImportedBuildings = false;
      const localSelectedBuildings = this.selectedBuildings;
      for (let i = 0; i < this.filteredBuildings.length; i++) {
        localSelectedBuildings[this.filteredBuildings[i].buildingId] = false;
      }
      this.selectedBuildings = localSelectedBuildings;
      this.showImportedBuildings = true;
    },
    nextProperty() {
      this.$store.dispatch('getNextActiveBuildingForStep2', this.$apollo);
    },
    onBuildingClick(event: MouseEvent, buildingId: number) {
      this.showImportedBuildings = false;
      if (event.shiftKey && this.lastSelectedBuildingId !== null) {
        this.selectRange(buildingId);
        this.lastSelectedBuildingId = null;
      } else {
        this.lastSelectedBuildingId = buildingId;
      }
      this.showImportedBuildings = true;
    },
    selectRange(buildingId: number) {
      const start = this.filteredBuildings.findIndex(
        (building: ProjectBuilding) => building.buildingId === this.lastSelectedBuildingId,
      );
      const end = this.filteredBuildings.findIndex((building: ProjectBuilding) => building.buildingId === buildingId);
      const [startIndex, endIndex] = start <= end ? [start, end] : [end, start];
      const localSelectedBuildings = this.selectedBuildings;
      for (let i = startIndex; i <= endIndex; i++) {
        localSelectedBuildings[this.filteredBuildings[i].buildingId] = true;
      }
      this.selectedBuildings = localSelectedBuildings;
      this.updateAssociations();
    },
    updateLiegenshaftWIEField() {
      // Get the active building
      const activeBuilding = this.buildings.find(
        (building: ProjectBuilding) => building.buildingId === this.activeStep2BuildingId,
      );
      // If the active building is not null and the liegenshaftWIE is not null
      if (activeBuilding && activeBuilding.wieNumber) {
        this.liegenshaftWIENumber = activeBuilding.wieNumber;
      } else {
        this.liegenshaftWIENumber = null;
      }
    },
    updateInstallationStartDate() {
      // Get the active building
      const activeBuilding = this.buildings.find(
        (building: ProjectBuilding) => building.buildingId === this.activeStep2BuildingId,
      );
      // If the active building is not null and the installationStartDate is not null
      if (activeBuilding && activeBuilding.installationStartDate) {
        this.installationStartDate = activeBuilding.installationStartDate;
      } else {
        this.installationStartDate = null;
      }
    },
    applyInstallationDateToAll() {
      this.showImportedBuildings = false;
      for (let i = 0; i < this.buildings.length; i++) {
        if (this.buildings[i].liegenshaft) {
          this.buildings[i].installationStartDate = this.installationStartDate;
          this.$store.commit('updateBuilding', this.buildings[i]);
        }
      }
      this.showImportedBuildings = true;
      this.addToastMessages({
        messages: [{ text: 'Wunschtermin-Einstellung, die für alle Liegenschaften gilt.', class: 'success' }],
        duration: 2000,
      });
    },
    clearInstallationDateForAll() {
      this.showImportedBuildings = false;
      this.installationStartDate = null;
      for (let i = 0; i < this.buildings.length; i++) {
        if (this.buildings[i].liegenshaft) {
          this.buildings[i].installationStartDate = null;
          this.$store.commit('updateBuilding', this.buildings[i]);
        }
      }
      this.showImportedBuildings = true;
    },
  },
});
