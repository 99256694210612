





























































import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { EneriqTypesMixin } from '@/features/app-heating-system/components/mixins/measurements/eneriq-types-mixin';
import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { BASE_COLUMNS, HeatingSystemSummary } from '@/features/domain-ui/heating-system-list-table/model';
import { StringProp } from '@/util/prop-decorators';
import { isString } from 'lodash';
import { Component, Mixins, Watch } from 'vue-property-decorator';

import query from '@/hsc-api/queries/HeatingSystemCollectionWithPaginationQuery.gql';
import heatingSystemsEnerIQQuery from '../heating-systems-eneriq-by-site-ids.gql';

import { APOLLO_CLIENT } from '@/features/core/container/model';
import {
  HeatingSystemCollectionWithPaginationQuery,
  HeatingSystemCollectionWithPaginationQueryVariables,
} from '@/hsc-api/queries/__generated__/HeatingSystemCollectionWithPaginationQuery';
import { HeatingSystemHealth } from '@/types/iot-portal';
import alertHeatingSystemSummariesQuery from '@/hsc-api/queries/AlertTicketsSummaryByHeatingSystemIdQuery.gql';
import {
  AlertHeatingSystemSummaries,
  AlertHeatingSystemSummariesVariables,
  AlertHeatingSystemSummaries_statuses,
} from '@/hsc-api/queries/__generated__/AlertHeatingSystemSummaries';
import { Option } from '@/features/ui/inputs/model';
import { AppHeatingSystemEnerIQBySiteIdsQuery } from '../__generated__/AppHeatingSystemEnerIQBySiteIdsQuery';
import {
  EnerIQEconomicUnitState,
  alarmStateIndicators,
} from '@/features/domain-ui/heating-system-list-table/eneriq-economic-unit';
import { EnerIQEconomicUnit } from '@/features/domain-ui/heating-system-list-table/eneriq-economic-unit';
import HeatingSystemsMetadataModal from './HeatingSystemsMetadataModal.vue';
import GroupsMixin from '@/features/core/components/mixins/groups';

type HeatingSystem = HeatingSystemCollectionWithPaginationQuery['heatingSystemsList']['items'][number];
type FilterStatus = 'ALL' | HeatingSystemHealth.CRITICAL | HeatingSystemHealth.DEGRADED;

@Component({
  mixins: [GroupsMixin],
  components: {
    HeatingSystemsMetadataModal,
  },
  apollo: {
    heatingSystemsList: {
      query,
      client: APOLLO_CLIENT.HEATING_SYSTEM_COLLECTOR_CLIENT,
      fetchPolicy: 'no-cache',
      pollInterval: 60000,
      variables(this: HeatingSystemsListView): HeatingSystemCollectionWithPaginationQueryVariables {
        return {
          customerIdOrSiteId: this.treeNodeId,
          skip: this.skip,
          take: this.take,
          searchQuery: this.searchQuery === '' ? null : this.searchQuery,
        };
      },
      async result(this: HeatingSystemsListView): Promise<void> {
        this.searchQuery = this.nextSearchQuery;
        // Filter out items with empty heatingSystemMeasurementGroups
        const filteredHeatingSystems = this.heatingSystems.filter(
          (system) => system.heatingSystemMeasurementGroups.length > 0,
        );
        const data = await this.heatingSystemSummary(filteredHeatingSystems);
        this.summaries =
          this.selectedStatus === 'ALL' ? data : data.filter((item) => item.health === this.selectedStatus);

        this.triggerMetadataModal();
      },
    },
  },
  data(this: HeatingSystemsListView) {
    return {
      hiddenColumns: [],
      selectedIdForOverlay: undefined,
      searchQuery: isString(this.$route.query.query) ? this.$route.query.query : '',
      summaries: [],
      isSummariesLoaded: false,
    };
  },
})
export default class HeatingSystemsListView extends Mixins(PaginationQueryStringMixin, EneriqTypesMixin) {
  // Variables in data
  private summaries?: HeatingSystemSummary[];
  private searchQuery!: string;
  private isSummariesLoaded = false;
  private selectedIdForOverlay?: string;
  private selectedStatus: FilterStatus = 'ALL';
  private summariesLoading = false;
  private enerIQLoading = false;

  @StringProp(true)
  private readonly treeNodeId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly heatingSystemsList?: HeatingSystemCollectionWithPaginationQuery['heatingSystemsList'];

  private hiddenColumns!: string[];

  private statusOptions: Option[] = [
    { value: 'ALL', label: 'Alle Meldungen' },
    { value: HeatingSystemHealth.CRITICAL, label: 'Kritische Meldungen' },
    { value: HeatingSystemHealth.DEGRADED, label: 'Warnende Meldungen' },
  ];

  private readonly BASE_COLUMNS = BASE_COLUMNS;
  private heatingSystemSummariesQuery!: Promise<HeatingSystemSummary[]>;
  private expandedRows: number[] = [];
  // Watch for changes to summaries, like when the load function is called
  @Watch('summaries')
  private onSummariesChanged(newVal: HeatingSystemSummary[]): void {
    this.isSummariesLoaded = !!newVal;
  }

  private get heatingSystems(): HeatingSystem[] {
    return this.heatingSystemsList?.items ?? [];
  }
  protected get count(): number {
    return this.heatingSystemsList?.count ?? 0;
  }

  private get nextSearchQuery(): string {
    return isString(this.$route.query.query) ? this.$route.query.query : '';
  }

  private set nextSearchQuery(value: string) {
    this.$router.replace({ query: { ...this.$route.query, query: value === '' ? undefined : value } });

    if (!this.$apollo.queries.heatingSystemsList.loading) {
      this.searchQuery = value;
    }
  }

  private get loading(): boolean {
    return this.$apollo.queries.heatingSystemsList.loading || this.summariesLoading || this.enerIQLoading;
  }

  private toggleRowExpansion(rowIndex: number): void {
    if (this.isRowExpanded(rowIndex)) {
      this.expandedRows = this.expandedRows.filter((currentRowIndex) => currentRowIndex !== rowIndex);
    } else {
      this.expandedRows.push(rowIndex);
    }
  }

  private isRowExpanded(rowIndex: number): boolean {
    return this.expandedRows.includes(rowIndex);
  }

  private async fetchAlertSummariesByHeatingSystem(
    heatingSystems: HeatingSystem[],
  ): Promise<AlertHeatingSystemSummaries_statuses[] | []> {
    try {
      this.summariesLoading = true;
      const heatingSystemIds = heatingSystems.map((hs) => hs.id);

      const variables: AlertHeatingSystemSummariesVariables = { hscIds: heatingSystemIds };
      const { data: alertTicketsData } = await this.$apollo.query<AlertHeatingSystemSummaries>({
        query: alertHeatingSystemSummariesQuery,
        client: APOLLO_CLIENT.HEATING_SYSTEM_COLLECTOR_CLIENT,
        variables,
        fetchPolicy: 'no-cache',
      });

      return alertTicketsData?.statuses || [];
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return [];
    } finally {
      this.summariesLoading = false;
    }
  }

  // fetch heating systems enerIQ data from portal based on siteIds,
  // and indicator is either yellow, yellow_red or red.
  private async fetchEnerIQData(
    siteIds: string[],
  ): Promise<AppHeatingSystemEnerIQBySiteIdsQuery['heatingSystems']['items']> {
    try {
      this.enerIQLoading = true;
      const { data } = await this.$apollo.query<AppHeatingSystemEnerIQBySiteIdsQuery>({
        query: heatingSystemsEnerIQQuery,
        client: APOLLO_CLIENT.PORTAL_CLIENT,
        variables: {
          siteIds,
        },
        fetchPolicy: 'no-cache',
      });
      return data.heatingSystems.items.filter((item) => {
        const enerIQ = item.enerIqConnectedEconomicUnit as unknown as EnerIQEconomicUnit | null;
        return enerIQ?.state?.recommendations?.some((recommendation) =>
          recommendation.recommendations.some((subRecommendation) =>
            alarmStateIndicators.includes(subRecommendation.indicator),
          ),
        );
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return [];
    } finally {
      this.enerIQLoading = false;
    }
  }

  private getComponentName(componentType: string): string {
    // strip out any numbers (i.e boiler1 -> boiler)
    let type = componentType.replace(/\d+/g, '');
    // check for tele heating without underscore
    if (type === 'teleheating') {
      type = 'tele_heating';
    }
    return this.components.find((item) => item.typeName === type.toUpperCase())?.label || '-';
  }

  private healthStatusLogic(heatingSystem: HeatingSystemSummary, enerIQMessages: string[]): HeatingSystemHealth {
    const statuses = heatingSystem.statuses && heatingSystem.statuses.length > 0 ? heatingSystem.statuses : [];
    // If there are both a GK heating system alert and EnerIQ recommendations, then the icon must be red
    if (statuses.length > 0 && enerIQMessages.length > 0) {
      return HeatingSystemHealth.CRITICAL;
    }
    // If there is only a GK heating system alert, then the icon must be red
    else if (statuses.length > 0 && enerIQMessages.length === 0) {
      return HeatingSystemHealth.CRITICAL;
    }
    // If there is only EnerIQ recommendations, then the icon must be yellow
    else if (statuses.length === 0 && enerIQMessages.length > 0) {
      return HeatingSystemHealth.DEGRADED;
    } else {
      // If there are no GK heating system alerts or EnerIQ recommendations, then the icon must be green
      return HeatingSystemHealth.OK;
    }
  }

  private async attachEnerIQData(heatingSystemSummary: HeatingSystemSummary[]): Promise<HeatingSystemSummary[]> {
    const siteIds = heatingSystemSummary.map((item) => item.siteId);
    const enerIqData = await this.fetchEnerIQData(siteIds);
    enerIqData.forEach((enerIQ) => {
      const state = enerIQ.enerIqConnectedEconomicUnit?.state as unknown as EnerIQEconomicUnitState;
      const heatingSystem = heatingSystemSummary.find((item) => item.siteId === enerIQ.site.id);
      const messageSet = new Set();
      if (heatingSystem) {
        state.recommendations.forEach((recommendation) => {
          const componentName = this.getComponentName(recommendation.context.title);
          const recommendations = recommendation.recommendations.filter((item) =>
            alarmStateIndicators.includes(item.indicator),
          );
          recommendations.forEach((recommendation) => {
            const message = recommendation.translations.filter((item) => item.lang === 'de')[0].value;
            messageSet.add(`${componentName}: ${message}`);
          });
        });

        const enerIQMessages = [...messageSet] as string[];
        heatingSystem.health = this.healthStatusLogic(heatingSystem, enerIQMessages);

        // add enerIQ message to current statuses array
        enerIQMessages.forEach((message) => {
          heatingSystem.statuses?.push(message);
        });
      }
    });
    return heatingSystemSummary;
  }

  private async heatingSystemSummary(heatingSystems: HeatingSystem[]): Promise<HeatingSystemSummary[]> {
    const alertSummarries = await this.fetchAlertSummariesByHeatingSystem(heatingSystems);
    const summaries: HeatingSystemSummary[] = [];
    for (const item of heatingSystems) {
      const status = alertSummarries.find((alert) => alert.id == item.id);
      const summary: HeatingSystemSummary = {
        name: item.name,
        health: status?.health ?? HeatingSystemHealth.OK,
        statusText: status?.statusText ?? '',
        statuses: status?.statuses ?? [],
        street: item.street,
        heatingType: item.heatingType,
        boilerDevice: item.boilerDevice,
        boilerModel: item.boilerModel,
        productSerial: item.productSerial,
        manufacturer: item.manufacturer,
        boilerManufacturer: item.boilerManufacturer,
        energyOutputRange: item.energyOutputRange,
        waterStorageType: item.waterStorageType,
        waterStorageModel: item.waterStorageModel,
        waterStorageManufacturer: item.waterStorageManufacturer,
        waterStorageCapacity: item.waterStorageCapacity,
        maintenanceCompany: item.maintenanceCompany,
        numApartmentUnits: item.numCommercialUnits,
        numCommercialUnits: item.numCommercialUnits,
        suppliedQuantity: item.suppliedQuantity,
        lastHydraulicBalancing: item.lastHydraulicBalancing,
        consumptions: item.heatingSystemConsumptions,
        components: item,
        siteId: item.siteId,
      };

      summaries.push(summary);
    }

    if (summaries.length > 0) {
      await this.attachEnerIQData(summaries);
    }

    return summaries;
  }

  private onUpdateStatus(value: FilterStatus): void {
    this.summaries = [];
    this.selectedStatus = value === null ? 'ALL' : value;
    this.$apollo.queries.heatingSystemsList.refetch();
  }

  private openModal(): void {
    this.$store.commit('openModal', true);
  }

  private triggerMetadataModal(): void {
    if (this.$route.query['open-metadata-modal'] && this.$route.query['open-metadata-modal'] === 'true') {
      this.$store.commit('openModal', true);
    }
  }
}
