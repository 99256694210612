














import { Component, Mixins } from 'vue-property-decorator';
import AlertAuditLog from '@/features/app-heating-system/views/tree-node/settings/AlertAuditLog.vue';
import GroupsMixin from '@/features/core/components/mixins/groups';

@Component({
  components: {
    AlertAuditLog,
  },
})
export default class AlertSettingsHeader extends Mixins(GroupsMixin) {
  private openModal = false;

  private toggleAuditView(): void {
    this.openModal = !this.openModal;
  }
}
